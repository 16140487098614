/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
// eslint-disable-next-line import/no-extraneous-dependencies

// import PageHeader from '../components/PageHeader'
import GallerySection from '../components/GallerySection'
import Layout from '../components/Layout'

/**
 * Filter posts by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {posts} object
 */
/**
 * filter posts by category.
 *
 * @param {posts} object
 * @param {title} string
 * @param {contentType} string
 */

// Export Template for use in CMS preview
const GalleryIndexTemplate = function ({ data }) {
  const { Gallery } = data.directus
  const galleryItems = []
  Gallery.forEach((i) => {
    if (i.imageCollection && i.imageCollection.length > 0) {
      galleryItems.push({
        featuredImage: i.image,
        eventDate: i.albumDate,
        description: i.description,
        id: i.id,
        imageCollection: i.imageCollection.map((f) => {
          return {
            imageUrl: `https://slwalters.clockworkbee.co.uk/assets/${f.directus_files_id.id}?format=jpg`,
            eventDate: f.directus_files_id.uploaded_on,
            id: f.directus_files_id.id
          }
        })
      })
    }
  })
  galleryItems.sort((a, b) => {
    return new Date(a.albumDate) - new Date(b.albumDate)
  })
  galleryItems.forEach((i) => {
    i.imageCollection.sort((a, b) => {
      return new Date(a.dateCreated) - new Date(b.dateCreated)
    })
  })
  return (
    <main className="Blog">
      {/* <PageHeader
        title={title}
        subtitle={subTitle}
        backgroundImage={featuredImage}
      /> */}

      {galleryItems.length > 0 && (
        <div>
          <section className="section">
            <div className="container">
              <h2>Our Galleries</h2>
              <GallerySection posts={galleryItems} />
            </div>
          </section>
        </div>
      )}
    </main>
  )
}

// Export Default BlogIndex for front-end
const GalleryIndex = function ({ data }) {
  return (
    <Layout meta={false} title={false}>
      <GalleryIndexTemplate data={data} />
    </Layout>
  )
}

export default GalleryIndex

export const pageQuery = graphql`
  query {
    directus {
      Gallery {
        id
        image {
          id
        }
        albumDate
        date_created
        date_updated
        description
        imageCollection {
          id
          directus_files_id {
            id
            uploaded_on
          }
        }
      }
    }
  }
`
